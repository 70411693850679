import BaseHelper from "../BaseHelper";
import { api } from "../../config";

class IndexHelper extends BaseHelper {
    static url = api.registrant;

    static search = (data, callback) => {
        return super.retrieve(`search`, data, callback);
    }

    static detail = (registration_number, callback) => {
        return super.post(`detail`, { registration_number }, callback, { success: false, error: false });
    }
}

export default IndexHelper;