<template>
  <el-container direction="vertical">
    <el-row type="flex" justify="center">
      <el-col :span="20" class="bg-grey2 section-form">
        <form @submit="handleSubmit">
          <el-col :span="18" class="bg-blue">
            <DxForm v-bind="form" ref="form" class="blue-label" @initialized="formInitialized" />
          </el-col>
          <el-col :span="2" :offset="1">
            <DxButton v-bind="button.search" ref="search" />
          </el-col>
        </form>
      </el-col>
    </el-row>

    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="20">
        <div class="search-title">{{ $t("searchTitle") }}</div>
        <div class="underline-blue"></div>
        <div class="search-desc">{{ $t("searchDesc") }}</div>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center">
      <el-col :span="20">
        <dx-table ref="table" :options="table" class="grid-blue grid-main table-container" />
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import { DxForm, DxButton } from 'devextreme-vue';
import { model } from '../../../lib';
import { RegistrantHelper } from '../../../helper/registrant';

export default {
  name: 'SectionForm',
  components: { DxForm, DxButton },

  data() {
    return {
      form: {
        formData: {
        },
        initialized: null,
        validationGroup: "validationGroup",
        showColonAfterLabel: false,
        items: this.$items([
          {
            itemType: "group",
            key: "group1", 
            colCount: 8, // Number of columns (1-12)
            items: [{
                itemType: "group",
                cssClass: "title",
                template: this.$t("searchFormLabel")
            }, {
                colSpan: 7,
                colCount: 1,
                itemType: "group",
                key: "group11", 
                cssClass:"content",
                items: [{
                    colSpan: 3,
                    dataField: "office_name",
                    label: { visible: false },
                    validationRules: ['required.検索条件'],
                }]
            }]
        }])
      },
      button: {
        search: {
            validationGroup: "validationGroup",
            class: "button-default-fill w-100",
            useSubmitBehavior: true,
            type: "default",
            text: this.$t("searchFormButton"),
        }
      },
      table: {
        helper: RegistrantHelper.search,
        isFirstLoad: false,
        allowColumnResizing: true,
        showRowLines: true,
        showColumnLines: true,
        allowColumnReordering: true,
        hoverStateEnabled: true,
        remoteOperations: true,
        columnResizingMode: "widget",
        parameter: () => {
            return {
                data: {
                    criteria: model(this.form),
                }
            }
        },
        onRefresh: ({ status, paging }) => {
            this.total = status ? paging.total : null
        },
        onRowClick: ({ data }) => {
          this.$router.push(`/registrar/${data.registration_number}`);
        },
        selection: {
            mode: "single"
        },
        scrolling: {
            showScrollbar: "onHover",
            useNative: true,
            mode: "infinite"
        },
        columns: [
          {
            dataField: "registration_number",
            caption: this.$t('table.registration_number'),
            sortOrder: "asc",
          },
          {
            dataField: "registered_office_name",
            caption: this.$t('table.registered_office_name'),
            template: function (options) {
              return <div>
                <p>{options.data.registrant_name}</p>
                <p>{options.value}</p>
              </div>
            }
          },
          {
            dataField: "applied_standard",
            caption: this.$t('table.applied_standard'),
          },
          {
            dataField: "registered_date",
            caption: this.$t('table.registered_date'),
          }
        ]
      }
    }
  },

  methods: {
    handleSubmit(e){
      e.preventDefault();

      this.$refs.table.refresh()
    },
    formInitialized(e){
      this.form.initialized = e.component
    }
  }
}
</script>

<style lang="scss" scoped>
.section-form {
  margin-top: 2rem;
  padding: 2rem;
}

.bg-blue {
  font-size: 12px;
  background: #2e51a3;
  padding-left: 10px ;
  color: #fff;
  border: 1px solid #dddddd;
}

.bg-grey2 {
  background: #efefef ;
}

.table-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #dddddd;
}

.search-title { 
  font-size: 18px;
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.search-desc {
  font-size: 14px;
  margin-top: 1rem;
}

.underline-blue {
  width: 100%;
  height: 2px;
  background: #2e51a3;
}
</style>